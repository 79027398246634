<template>
  <div>
    <div v-if="connected" class="w-6/12 mx-auto">
      <div class="font-bold text-4xl">
        Continue To Create
      </div>
      <div class="mt-10">
        <div class="font-semibold">
          Name
        </div>
        <div>
          <el-input v-model="name" placeholder="请输入内容"></el-input>
        </div>
      </div>

      <div class="mt-10">
        <div class="font-medium">
          Content
        </div>
        <div>
          <el-input
              class="mt-4"
              placeholder="请输入内容"
              v-model="input1"
              clearable>
          </el-input>
          <el-input
              class="mt-4"
              placeholder="请输入内容"
              v-model="input2"
              clearable>
          </el-input>
          <el-input
              class="mt-4"
              placeholder="请输入内容"
              v-model="input3"
              clearable>
          </el-input>
          <el-input
              class="mt-4"
              placeholder="请输入内容"
              v-model="input4"
              clearable>
          </el-input>
          <el-input
              class="mt-4"
              placeholder="请输入内容"
              v-model="input5"
              clearable>
          </el-input>
          <el-input
              class="mt-4"
              placeholder="请输入内容"
              v-model="input6"
              clearable>
          </el-input>
          <el-input
              class="mt-4"
              placeholder="请输入内容"
              v-model="input7"
              clearable>
          </el-input>
          <el-input
              class="mt-4"
              placeholder="请输入内容"
              v-model="input8"
              clearable>
          </el-input>
        </div>
      </div>

      <div class="mt-10">
        <div>Description</div>
        <div>
          <el-input
              type="textarea"
              placeholder="请输入内容"
              v-model="description"
              maxlength="3000"
              show-word-limit
              :autosize="{minRows:4}"
          />
        </div>
      </div>
      <div class="mt-10">
        <div class="flex justify-between items-center">
          <div class="flex items-center">
            <span class="el-icon-s-fold"></span>
            <div class="flex flex-col ml-5">
              <span class="text-medium">Properties</span>
              <span class="text-xs" style="color: rgb(190,190,190)">properties of your nft</span>
            </div>
          </div>
          <div>
            <el-button type="primary" icon="el-icon-plus" @click="createNFT"></el-button>
          </div>
        </div>

      </div>
      <div  class="mt-10 mb-20">
        <el-button type="primary" @click="createNFT">
          create
        </el-button>
      </div>
    </div>
    <connect-wallet v-else></connect-wallet>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {initVuexMetaMask} from "../assets/help/connect";
import ConnectWallet from "../components/myself/connectWallet";

export default {
  name: "create",
  props:{
    tokenIdFrom:{
      type:Number,
      default:0
    }
  },
  components: {ConnectWallet},
  data(){
    return {
      name:"",
      description:"",
      dynamicTags: ['标签一', '标签二', '标签三'],
      inputVisible: false,
      inputValue: '',
      input1:'',
      input2:"",
      input3:"",
      input4:"",
      input5:"",
      input6:"",
      input7:"",
      input8:"",
      inConnect:false
    }
  },
  computed:mapState(["signer","connectTextContract","connected"]),
  methods:{
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    async createNFT(){

      console.log("in connected!!!",this.connected,this.inConnect)
      if(this.connected===false&&this.inConnect===false){
        this.inConnect=true
        await initVuexMetaMask.apply(this)
        this.inConnect=false
      }

      let resultContent=[this.input1,this.input2,this.input3,this.input4,this.input5,this.input6,this.input7,this.input8]
      let meta=[this.name,this.description]

      let tx=await this.connectTextContract.mint(this.tokenIdFrom,resultContent,meta)

      this.$message({
        message: "congratulation your transactionHash is:"+tx.hash,
        type: 'success'
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    }
  }
}
</script>

<style scoped>
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>